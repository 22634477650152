
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">            <div v-if="user.user_role!='viewer'">

			<!-- spacebetween  -->
             <div class="d-flex justify-space-between">
<h1 class="mt-4 mb-5">{{$store.getters.language.data.employees.title}}</h1>
<v-btn color="primary" :to="'/deactive-employees-view'" class="mt-4">
    <!-- mdi create user -->
    <v-icon>mdi-account-minus</v-icon> Deactivated Employees
</v-btn>
                <v-btn color="primary" :to="'/users-list'" class="mt-4">
                   <!-- mdi create user -->
                    <v-icon>mdi-account-plus</v-icon>
                </v-btn>
                <!-- btn to go to /deactive-employees-view -->
                

             </div>

			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-text-field v-model="search" :label="$store.getters.language.data.extra.search" append-icon="mdi-magnify" outlined></v-text-field>

					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="employee_id">
								<template v-slot:[`item.employee_id`]="{ item }">
									<div>
										<v-btn icon :to="'/employees-list/'+item.employee_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
										<v-btn color="error" icon class="mx-1" @click="selectEmployees(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn>
									</div>
								</template>
								<template v-slot:[`item.employee_name`]="{ item }">
									<div>
										<v-btn text :to="'/employees-list/'+item.employee_id" class="mx-1">
                                            {{ item.employee_name }}
										</v-btn>
										
									</div>
								</template>
								<template v-slot:[`item.employee_birth`]="{ item }">
									<div>
										{{ new Date(item.employee_birth).toLocaleDateString() }}
									</div>
								</template>
								<template v-slot:[`item.employee_contract_start_date`]="{ item }">
									<div>
										{{ new Date(item.employee_contract_start_date).toLocaleDateString() }}
									</div>
								</template>
								<template v-slot:[`item.employee_contract_end_date`]="{ item }">
									<div>
										{{ new Date(item.employee_contract_end_date).toLocaleDateString() }}
									</div>
								</template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteEmployeesList">{{$store.getters.language.data.employees.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
            </div>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.employees.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.employees.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteEmployees(selected_employees.employee_id)">
						{{$store.getters.language.data.employees.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from '../../requests/employees.request.js'
	export default {
		data() {
			return {
				employees: {},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_employees: {},
				delete_dialog: false,
				headers: [


					{
						text: this.$store.getters.language.data.employees.employee_name,
						align: 'start',
						sortable: true,
						value: 'employee_name',
					},
					{
						text: this.$store.getters.language.data.employees.employee_birth,
						align: 'start',
						sortable: true,
						value: 'employee_birth',
					},
					{
						text: this.$store.getters.language.data.positions.position_name,
						align: 'start',
						sortable: true,
						value: 'position_name',
					},
					{
						text: this.$store.getters.language.data.employees.employee_contract_start_date,
						align: 'start',
						sortable: true,
						value: 'employee_contract_start_date',
					},
					{
						text: this.$store.getters.language.data.employees.employee_contract_end_date,
						align: 'start',
						sortable: true,
						value: 'employee_contract_end_date',

					},
					{
						text: this.$store.getters.language.data.employees.employee_work_place,
						align: 'start',
						sortable: true,
						value: 'employee_work_place',
					},
					{
						text: this.$store.getters.language.data.employees.employee_phone_1,
						align: 'start',
						sortable: true,
						value: 'employee_phone_1',
					},
					{
						text: this.$store.getters.language.data.employees.employee_phone_2,
						align: 'start',
						sortable: true,
						value: 'employee_phone_2',
					},
					{
						text: this.$store.getters.language.data.working_hours.title,
						align: 'start',
						sortable: true,
						value: 'hours',
					},
					{
						text: this.$store.getters.language.data.employees.employee_status,
						align: 'start',
						sortable: true,
						value: 'employee_status',
					},
					{
						text: this.$store.getters.language.data.employees.employee_off_days_year,
						align: 'start',
						sortable: true,
						value: 'employee_off_days_year',
					},
					{
						text: this.$store.getters.language.data.employees.employee_off_hours_year,
						align: 'start',
						sortable: true,
						value: 'employee_off_hours_year',
					},
					{
						text: this.$store.getters.language.data.employees.employee_base_salary,
						align: 'start',
						sortable: true,
						value: 'employee_base_salary',
					},
					{
						text: this.$store.getters.language.data.employees.employee_food_budget,
						align: 'start',
						sortable: true,
						value: 'employee_food_budget',
					},
					{
						text: this.$store.getters.language.data.employees.employee_transportation_budget,
						align: 'start',
						sortable: true,
						value: 'employee_transportation_budget',
					},
					{
						text: this.$store.getters.language.data.employees.employee_phone_budget,
						align: 'start',
						sortable: true,
						value: 'employee_phone_budget',
					},
					{
						text: this.$store.getters.language.data.employees.employee_driver_budget,
						align: 'start',
						sortable: true,
						value: 'employee_driver_budget',
					},
                    	{
						text: this.$store.getters.language.data.employees.employee_government_insurance,
						align: 'start',
						sortable: true,
						value: 'employee_government_insurance',
					},
					{
						text: this.$store.getters.language.data.employees.employee_insurance_rate,
						align: 'start',
						sortable: true,
						value: 'employee_insurance_rate',
					},
					{
						text: this.$store.getters.language.data.employees.supervisor,
						align: 'start',
						sortable: true,
						value: 'supervisor',
					},

					{
						text: this.$store.getters.language.data.users.user_name,
						align: 'start',
						sortable: true,
						value: 'user_name',
					}, {
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'employee_id',
					}
				],
			}
		},
		computed: {
			positions() {
				return this.$store.getters.positions_list
			},
			working_hours() {
				return this.$store.getters.working_hours_list
			},
			users() {
				return this.$store.getters.users_list
			},
            user() {
                return this.$store.getters.user
            }
		},
		mounted() {
			this.readEmployees();
		},
		methods: {
			addEmployees() {
				this.loading_btn = true
				requests.createEmployees(this.employees).then(r => {
					if (r.status == 200) {
						this.employees = {}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'Employees Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add Employees',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteEmployees(employee_id) {
				requests.deleteEmployees(employee_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.employee_id != employee_id
						})
						this.snackbar = {
							value: true,
							text: 'Employees Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteEmployeesList() {
				let ids = this.selected_rows.map(m => m.employee_id)
				requests.deleteEmployeesList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.employee_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' Employees Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readEmployees() {
				this.loading = true
				requests.getAllEmployees().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employees',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employees',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectEmployees(i) {
				this.selected_employees = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    